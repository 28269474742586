<template>
    <div ref="earnFrame"
        class="relative h-full w-full flex justify-center items-center transition-all duration-700 opacity-0 hidden">
        <img src="../assets/img/background-main.png" class="h-full w-full object-cover">
        <div class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
            <div class="w-[90%] h-[80%] flex flex-col justify-start items-start overflow-y-auto no-scrollbar">
                <!-- <div class="w-full h-[7%] flex">
                    <div class="h-full w-[40%] flex justify-center items-center">
                        <div class="h-full aspect-square">
                            <img src="../assets/img/points.webp" class="h-full">
                        </div>
                        <div class="h-full w-full flex flex-col gap-[10%] justify-center items-start">
                            <h1 class="font-montserrat text-[#CDFB2D] text-[2vh] font-[600] leading-none ml-[7%]">{{
                                userName }}</h1>
                            <h1 class="font-montserrat text-[#D9D9D9] text-[1.6vh] font-[400] leading-none ml-[7%]">{{
                                walletAddress }}</h1>
                        </div>
                    </div>
                    <div class="h-full w-[60%] flex justify-end items-center">
                        <div
                            class="h-[60%] w-fit flex justify-center items-center border border-[#d9d9d936] pl-[3%] pr-[4%] rounded-3xl backdrop-blur-3xl box-shadow mr-[5%]">
                            <img src="../assets/img/points.webp" class="h-[70%]">
                            <h1
                                class="font-montserrat text-[#D9D9D9] text-[1.6vh] font-[600] leading-none ml-[7%] mt-[2%]">
                                {{ userTotalPoints }}</h1>
                        </div>
                        <div
                            class="h-[60%] w-fit flex justify-center items-center border border-[#d9d9d936] pl-[3%] pr-[4%] rounded-3xl backdrop-blur-3xl box-shadow">
                            <img src="../assets/img/xDefin.webp" class="h-[70%]">
                            <h1
                                class="font-montserrat text-[#D9D9D9] text-[1.6vh] font-[600] leading-none ml-[7%] mt-[2%]">
                                {{ userTotalXdefin }}</h1>
                        </div>
                    </div>
                </div> -->

                <div ref="earnDashboardRef"
                    class="w-full h-[90%] mt-[7%] flex flex-col justify-start items-start transition-all duration-500 overflow-y-scroll no-scrollbar">

                    <!-- <div class="relative w-full h-fit border border-[#d9d9d97e] overflow-hidden rounded-lg mt-[2%]">
                        <img src="../assets/img/earn/background-pool.png" class="w-full">
                        <div
                            class="absolute top-0 left-0 w-full h-full flex flex-col gap-[5%] justify-center items-center">
                            <h1 class="font-montserrat text-[#D9D9D9] font-[500] leading-none text-[2vh] mt-[1%]">Total
                                prize pools</h1>
                            <div class="w-fit h-fit flex gap-[3%]">
                                <img src="../assets/img/xDefin.webp" class="h-[3.5vh]">
                                <h1 class="font-montserrat text-[#CDFB2D] font-[700] leading-none text-[3.5vh]">{{
                                totalPrizePools }}</h1>
                                <h1
                                    class="font-montserrat text-[#CDFB2D] font-[700] leading-none text-[3.5vh] mr-[2.3vh]">
                                    xDefin</h1>
                            </div>
                        </div>
                    </div> -->

                    <h1 class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none mb-[4%]">Daily
                        check-in</h1>

                    <div class="relative w-full h-[13%] min-h-[13%] border border-[#CDFB2D] rounded-lg flex mb-[4%]">
                        <div class="h-full w-[65%] flex gap-[10%] flex-col justify-center items-start">
                            <h1
                                class="font-montserrat text-[#D9D9D9] font-[700] leading-none text-[2vh] underline ml-[5%]">
                                DAY {{ totalDayChecked + 1 }}</h1>
                            <h1 class="font-montserrat text-[#D9D9D9] font-[400] leading-none text-[1.6vh] ml-[5%]">
                                Don’t miss your attention, your <br> bonus will be reset to <span class="font-[700]">100
                                    $xDEF</span></h1>
                        </div>
                        <div v-if="isTodayChecked" class="absolute top-0 left-0 w-full h-full flex justify-end">
                            <div ref="dailyCheckinRef" @click="dailyCheckinBtnClick"
                                class="h-full w-[35%] border-custom bg-[#161616] rounded-lg transition-all duration-700 flex flex-col gap-[7%] justify-center items-center">
                                <h1
                                    class="font-montserrat text-[#D9D9D9] font-[700] leading-none text-[2vh] transition-all duration-300">
                                    Check-in</h1>
                                <div class="w-fit h-[30%] flex justify-center items-center">
                                    <h1 class="font-montserrat text-[#CDFB2D] font-[700] leading-none text-[2vh]">
                                        + {{ nextPointCheckin }}</h1>
                                    <img src="../assets/img/xDefin.webp" class="h-[80%] ml-[5px]">
                                </div>
                            </div>
                        </div>
                        <div v-else class="absolute top-0 left-0 w-full h-full flex justify-end">
                            <div
                                class="h-full w-[100%] border-custom bg-[#161616] rounded-lg transition-all duration-700 flex flex-col gap-[7%] justify-center items-center">
                                <h1
                                    class="font-montserrat text-[#D9D9D9] font-[700] leading-none text-[2vh] transition-all duration-300">
                                    You’ve checked-in!</h1>
                                <div class="w-fit h-[30%] flex justify-center items-center">
                                    <h1 class="font-montserrat text-[#CDFB2D] font-[700] leading-none text-[2vh]">
                                        + {{ lastDayCheckedPoints }}</h1>
                                    <img src="../assets/img/points.webp" class="h-[80%] ml-[5px]">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="relative w-full h-[7%] min-h-[7%] mb-[1%] flex justify-center items-center">
                        <div class="flex w-[99%] h-full">
                            <div v-for="index in 13" :key="index"
                                class="flex-1 h-full flex justify-center items-center">
                                <template v-if="index % 2 === 1">
                                    <img src="../assets/img/earn/checked.webp"
                                        :class="{ 'opacity-30': !isChecked(index) }" class="w-full" />
                                </template>
                                <template v-else>
                                    <hr :class="{
                                        'border border-[#CDFB2D] w-full': shouldShowLine(index),
                                        'border-0': !shouldShowLine(index)
                                    }" />
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="w-full h-fit flex justify-between mb-[7%]">
                        <div v-for="index in 7" :key="index" class="h-fit w-fit">
                            <h1 :class="{ 'opacity-30': !dataCheckin[index - 1] }"
                                class="font-montserrat text-[#D9D9D9] font-[400] leading-none text-[1.6vh]">Day {{
                                    getWeekRange(totalDayChecked, index - 1) }}</h1>
                        </div>
                    </div>

                    <!-- <h1 class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none mb-[5%]">Daily
                        tasks</h1> -->

                    <div
                        class="relative w-full h-[7%] min-h-[7%] mb-[3%] flex justify-center items-center border-b border-[#d9d9d97e]">
                        <!-- <div class="w-full h-[80%] flex">
                            <div class="h-full w-[65%] flex justify-start items-center">
                                <h1
                                    class="bullet-point font-montserrat text-[#D9D9D9] font-[400] leading-none text-[1.8vh]">
                                    Connect your phone <br> &ensp; number to get <span class="font-[700]">Bonus
                                        points</span></h1>
                            </div>
                            <div class="h-full w-[35%] flex justify-end items-center">
                                <div @click="connectPhoneClick"
                                    class="w-[90%] h-[60%] border flex justify-center items-center gap-[5%] pl-[8%] pr-[8%] border-[#d9d9d936] rounded-3xl backdrop-blur-3xl box-shadow transition-all active:scale-90">
                                    <h1 class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none">
                                        Connect</h1>
                                    <img src="../assets/img/earn/phone.webp" class="h-[60%]">
                                </div>
                            </div>
                        </div> -->
                        <div class="h-full w-full flex gap-[7%]">
                            <div ref="definTasksBtn"
                                class="h-full w-fit flex justify-center items-center border-b-[2px] border-[#CDFB2D]">
                                <h1 class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none mb-[5%]">
                                    DeFin tasks ({{
                                        totalDefinTaskRemaining }})</h1>
                            </div>
                            <div ref="partnerTasksBtn"
                                class="h-full w-fit flex justify-center items-center opacity-[50%]">
                                <h1 class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none mb-[5%]">
                                    Partner tasks ({{
                                        totalParnerTaskRemaining }})</h1>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="relative w-full h-[13%] min-h-[13%] mb-[3%] flex justify-center items-center">
                        <div class="h-[70%] w-full flex">
                            <div class="h-full aspect-square flex items-center">
                                <img src="../assets/img/earn/time.webp" class="h-[80%]">
                            </div>
                            <div class="h-full w-full flex justify-between items-center">
                                <div class="h-full w-fit flex flex-col gap-[10%] justify-center items-start">
                                    <h1 class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none">
                                        Tasks</h1>
                                    <h1 class="font-montserrat text-[#D9D9D9] text-[1.8vh] font-[400] leading-none">
                                        {{ totalDefinTaskRemaining }} remaining tasks</h1>
                                </div>
                                <div class="h-full w-[35%] flex justify-end items-center">
                                    <div @click="dailyTasksClick"
                                        class="w-full h-[65%] border flex justify-center items-center gap-[7%] pl-[6%] pr-[6%] border-[#d9d9d936] rounded-3xl backdrop-blur-3xl box-shadow transition-all active:scale-90">
                                        <h1
                                            class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none pt-[2%]">
                                            +{{ totalPointsRemaining }}</h1>
                                        <img src="../assets/img/xDefin.webp" class="h-[60%]">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <!-- defin tasks -->
                    <div class="w-full h-auto">
                        <h1 v-if="taskListData.filter(task => task.number > -1).length > 0"
                            class="font-montserrat text-[#D9D9D9] text-[2vh] font-[500] leading-none mb-[3%] mt-[3%]">
                            Basic Tasks</h1>

                        <div v-for="task in taskListData.filter(task => task.number > -1 && task.taskGroup !== 'signin')"
                            :key="task"
                            class="h-auto w-full flex justify-between items-center border-b border-[#d9d9d97e]">
                            <div class="h-auto w-4/5 flex flex-col gap-[0.5vh] pt-[5%] pb-[5%]">
                                <h1 class="font-montserrat text-[#ffffff] font-[500] leading-none text-[2vh]">
                                    {{ task.taskName }}
                                </h1>
                                <h1 class="font-montserrat text-[#d9d9d9c4] text-[1.8vh] font-[700] leading-none">
                                    +{{ task.points }} $vDEF</h1>
                            </div>
                            <div class="h-auto w-1/6 flex">
                                <div @click="submitTask(task)" class="w-full h-[3.5vh] border flex justify-center items-center
                                     gap-[5%] pl-[8%] pr-[8%] border-[#cefb2d7e] rounded-3xl
                                      backdrop-blur-3xl box-shadow transition-all active:scale-90">
                                    <h1 class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none">
                                        DO</h1>
                                </div>
                            </div>
                        </div>

                        <h1 v-if="taskListData.filter(task => task.number == -1).length > 0"
                            class="font-montserrat text-[#D9D9D9] text-[2vh] font-[500] leading-none mt-[7%] mb-[3%]">
                            Daily Tasks</h1>

                        <div v-for="task in taskListData.filter(task => task.number == -1)" :key="task"
                            class="h-auto w-full flex justify-between items-center border-b border-[#d9d9d97e]">
                            <div class="h-auto w-4/5 flex flex-col gap-[0.5vh] pt-[5%] pb-[5%]">
                                <h1 class="font-montserrat text-[#ffffff] font-[500] leading-none text-[2vh]">
                                    {{ task.taskName }}
                                </h1>
                                <h1 class="font-montserrat text-[#d9d9d9c4] text-[1.8vh] font-[700] leading-none">
                                    +{{ task.points }} $vDEF</h1>
                            </div>
                            <div class="h-auto w-1/6 flex">
                                <div @click="submitTask(task)" class="w-full h-[3.5vh] border flex justify-center items-center
                                     gap-[5%] pl-[8%] pr-[8%] border-[#cefb2d7e] rounded-3xl
                                      backdrop-blur-3xl box-shadow transition-all active:scale-90">
                                    <h1 class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none">
                                        DO</h1>
                                </div>
                            </div>
                        </div>



                    </div>


                </div>

                <!-- <div ref="dailyTasksRef"
                    class="w-full h-[83%] mt-[7%] flex flex-col justify-start items-start transition-all duration-500 opacity-0 hidden overflow-y-scroll no-scrollbar">

                    <h1 v-if="taskListData.filter(task => task.number > -1).length > 0"
                        class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none mb-[3%]">
                        Basic Tasks</h1>

                    <div v-for="task in taskListData.filter(task => task.number > -1 && task.taskGroup !== 'signin')"
                        :key="task"
                        class="relative w-full aspect-[6/1] flex justify-center items-center border-b border-[#d9d9d97e]">
                        <div class="w-full h-[80%] flex">
                            <div class="h-full w-[65%] flex justify-start items-center">
                                <h1 class="font-montserrat text-[#D9D9D9] font-[400] leading-none text-[1.8vh]">
                                    {{ task.taskName }}
                                </h1>
                            </div>
                            <div class="h-full w-[35%] flex justify-end items-center">
                                <div @click="submitTask(task)" :ref="el => taskRefs[task.taskId] = el"
                                    class="w-[90%] h-[60%] border flex justify-center items-center gap-[5%] pl-[8%] pr-[8%] border-[#d9d9d936] rounded-3xl backdrop-blur-3xl box-shadow transition-all active:scale-90">
                                    <h1 class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none">
                                        +{{ task.points }}</h1>
                                    <img src="../assets/img/xDefin.webp" class="h-[60%] max-h-[55px]">
                                </div>
                            </div>
                        </div>
                    </div>

                    <h1 v-if="taskListData.filter(task => task.number == -1).length > 0"
                        class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none mt-[10%] mb-[3%]">
                        Daily Tasks</h1>

                    <div v-for="task in taskListData.filter(task => task.number == -1)" :key="task"
                        class="relative w-full aspect-[6/1] flex justify-center items-center border-b border-[#d9d9d97e]">
                        <div class="w-full h-[80%] flex">
                            <div class="h-full w-[65%] flex justify-start items-center">
                                <h1 class="font-montserrat text-[#D9D9D9] font-[400] leading-none text-[1.8vh]">
                                    {{ task.taskName }}
                                </h1>
                            </div>
                            <div class="h-full w-[35%] flex justify-end items-center">
                                <div @click="submitTask(task)" :ref="el => taskRefs[task.taskId] = el"
                                    class="w-[90%] h-[60%] border flex justify-center items-center gap-[5%] pl-[8%] pr-[8%] border-[#d9d9d936] rounded-3xl backdrop-blur-3xl box-shadow transition-all active:scale-90">
                                    <h1 class="font-montserrat text-[#D9D9D9] text-[2vh] font-[700] leading-none">
                                        +{{ task.points }}</h1>
                                    <img src="../assets/img/points.webp" class="h-[60%] max-h-[55px]">
                                </div>
                            </div>
                        </div>
                    </div>

                </div> -->

            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import * as Utils from '../assets/controller/utilities';
import { useState } from '../assets/controller/state';
import * as definAPI from '../assets/controller/api';

export default {
    name: 'EarnDefin',
    components: {

    },
    setup(props, { expose }) {
        const state = useState();
        const earnFrame = ref(null);
        const userTotalPoints = ref(0);
        const userName = ref('');
        const walletAddress = ref('');
        const totalPrizePools = ref('100.000.000');
        const userTotalXdefin = ref('0');
        const dailyCheckinRef = ref(null);
        const dataCheckin = ref([
            false, false, false, false, false, false, false
        ]);
        const nextPointCheckin = ref();
        const totalDayChecked = ref(0);
        const totalDefinTaskRemaining = ref(0);
        const totalPointsRemaining = ref(0);
        const earnDashboardRef = ref(null);
        const dailyTasksRef = ref(null);
        const taskListData = ref([]);
        const taskRefs = ref({});
        const isTodayChecked = ref(true);
        const lastDayCheckedPoints = ref(0);
        const definTasksBtn = ref(null);
        const partnerTasksBtn = ref(null);
        const totalParnerTaskRemaining = ref(0);


        expose({
            earnFrame,
            showEarnFrame,
            hideEarnFrame
        });

        function showEarnFrame() {
            userName.value = state.getUserInfoFromServer().teleAccount;
            userTotalPoints.value = state.getUserInfoFromServer().totalPoints;
            earnFrame.value.classList.remove('hidden');
            setTimeout(() => {
                earnFrame.value.classList.remove('opacity-0');
            }, 10);
        }

        function hideEarnFrame() {
            earnFrame.value.classList.add('opacity-0');
            setTimeout(() => {
                earnFrame.value.classList.add('hidden');
            }, 700);
        }

        function menuEventClickListener() {
            document.addEventListener("menuEventResponse", (event) => {
                if (event.detail.message == 2) {
                    hideDailyTasks();
                    setTimeout(() => {
                        showEarnDashboard();
                    }, 500);
                }
            });
        }

        async function dailyCheckinBtnClick() {
            if (!isTodayChecked.value) {
                return;
            }
            const checkinResult = await definAPI.submitCheckin(getTaskIdCheckin(taskListData.value), state.getUserInfoFromServer().userId)
            if (checkinResult.success) {
                dailyCheckinRef.value.classList.remove('w-[35%]');
                dailyCheckinRef.value.classList.add('w-[100%]');
                const btnText = dailyCheckinRef.value.querySelector('h1');
                setTimeout(() => {
                    btnText.classList.add('opacity-0');
                    setTimeout(() => {
                        btnText.textContent = 'You’ve checked-in!'
                        btnText.classList.remove('opacity-0');
                        totalDayChecked.value += 1;
                        isTodayChecked.value = false;
                        lastDayCheckedPoints.value = nextPointCheckin.value;
                        Utils.updatePointsDispatchEvent({ type: 'add', value: nextPointCheckin.value })
                        Utils.dataDispatchEvent({ callback: 2 });
                        Utils.dataDispatchEvent({ callback: 1 });
                    }, 300);
                }, 100);
            } else {
                Utils.toastError('An error occurred during check in!');
            }
        }

        function shouldShowLine(index) {
            const dataIndex = (index / 2) - 1;
            if (dataIndex < 0 || dataIndex >= dataCheckin.value.length - 1) {
                return false;
            }
            return dataCheckin.value[dataIndex] && dataCheckin.value[dataIndex + 1];
        }

        function isChecked(index) {
            const dataIndex = (index + 1) / 2 - 1;
            return dataCheckin.value[dataIndex];
        }


        function getWeekRange(day, index) {
            const startDay = Math.floor((day - 1) / 7) * 7 + 1;
            const days = [];
            for (let i = 0; i < 7; i++) {
                let dayValue;
                if (day > 0) {
                    dayValue = (startDay + i);
                } else {
                    dayValue = (i + 1);
                }
                days.push(dayValue);
                if (dayValue <= day) {
                    dataCheckin.value[i] = true;
                }
            }
            return days[index];
        }


        function showCheckedDays(totalDayChecked) {
            if (totalDayChecked > 0) {
                const startDay = Math.floor((totalDayChecked - 1) / 7) * 7 + 1;
                let day;
                for (let i = 0; i < 7; i++) {
                    day = startDay + i;
                    dataCheckin.value[i] = true;
                    if (day === totalDayChecked) {
                        return;
                    }
                }
            }
        }

        function dailyTasksClick() {
            Utils.menuDispatchEvent({ backButton: 2, title: 'Tasks' });
            hideEarnDashboard();
            setTimeout(() => {
                showDailyTasks();
            }, 500);
        }

        function showEarnDashboard() {
            earnDashboardRef.value.classList.remove('hidden');
            setTimeout(() => {
                earnDashboardRef.value.classList.remove('opacity-0');
            }, 10);
        }

        function hideEarnDashboard() {
            earnDashboardRef.value.classList.add('opacity-0');
            setTimeout(() => {
                earnDashboardRef.value.classList.add('hidden');
            }, 500);
        }

        function showDailyTasks() {
            dailyTasksRef.value.classList.remove('hidden');
            setTimeout(() => {
                dailyTasksRef.value.classList.remove('opacity-0');
            }, 10);
        }

        function hideDailyTasks() {
            dailyTasksRef.value.classList.add('opacity-0');
            setTimeout(() => {
                dailyTasksRef.value.classList.add('hidden');
            }, 500);
        }

        function connectPhoneClick() {
            Utils.toastInfo('We are cooking it!');
        }

        function isToday(date) {
            const today = new Date();
            return date.getDate() === today.getDate() &&
                date.getMonth() === today.getMonth() &&
                date.getFullYear() === today.getFullYear();
        }

        function totalRemainingPoints(data) {
            let remainingPoints = 0;
            data.forEach(task => {
                remainingPoints += task.points;
            });
            return remainingPoints;
        }

        function getTaskIdCheckin(taskListData) {
            for (let i = 0; i < taskListData.length; i++) {
                if (taskListData[i].taskType == "checkin") {
                    return taskListData[i].taskId;
                }
            }
            return -1;
        }

        function getLastDayCheckedPoints(pointHistory) {
            for (let i = 0; i < pointHistory.length; i++) {
                if (pointHistory[i].taskName == "Daily Check-in") {
                    return pointHistory[i].point;
                }
            }
            return 0;
        }

        async function submitTask(task) {
            const { userId, invitationCode } = state.getUserInfoFromServer();
            let taskResult;

            if (task.taskGroup === 'social') {
                taskResult = await definAPI.submitSocial(task.taskId, userId, "unknown", task.link);
                state.openLink(task.link);
            } else if (task.taskGroup === 'invite') {
                taskResult = await definAPI.submitInvite(task.taskId, userId, invitationCode);
            } else if (task.taskGroup === 'checkin') {
                taskResult = await definAPI.submitCheckin(task.taskId, userId);
            }


            if (taskResult) {
                if (!taskResult.success) {
                    Utils.toastError('Task not completed!');
                } else {
                    if (task.taskType === 'checkin') {
                        lastDayCheckedPoints.value = task.points;
                        isTodayChecked.value = false;
                        totalDayChecked.value += 1;
                        showCheckedDays(totalDayChecked.value);
                    }
                    Utils.toastSuccess(`You have received ${task.points} points`);
                    Utils.updatePointsDispatchEvent({ type: 'add', value: task.points });
                    Utils.dataDispatchEvent({ callback: 2 });
                    Utils.dataDispatchEvent({ callback: 1 });
                }
            }
        }

        function dataEventListener() {
            document.addEventListener("dataEvent", async (event) => {
                const data = event.detail.message;
                const userId = state.getUserInfoFromServer().userId;

                if (data.callback === -1 || data.callback === 2) {
                    const checkinHistory = await definAPI.getCheckins(userId);
                    const checkedInDays = checkinHistory.data.checkedin;
                    totalDayChecked.value = checkedInDays.length;

                    if (totalDayChecked.value > 0) {
                        isTodayChecked.value = isToday(new Date(checkedInDays[0]));
                    }

                    if (!isTodayChecked.value) {
                        const pointHistory = await definAPI.getHistoryTask(userId);
                        lastDayCheckedPoints.value = getLastDayCheckedPoints(pointHistory.data);
                    } else {
                        nextPointCheckin.value = checkinHistory.data.nextCheckinPoint;
                    }

                    showCheckedDays(totalDayChecked.value);

                    const missions = await definAPI.getMissions(userId);
                    taskListData.value = missions.data;

                    const taskListDataFilter = taskListData.value.filter(task => task.taskGroup !== 'signin');
                    totalPointsRemaining.value = totalRemainingPoints(taskListDataFilter);
                    totalDefinTaskRemaining.value = taskListDataFilter.length;
                }
            });
        }

        function updatePointsListener() {
            document.addEventListener("pointsEvent", async (event) => {
                const data = event.detail.message;
                if (data.type === 'add') {
                    setTimeout(() => {
                        userTotalPoints.value = state.getUserInfoFromServer().totalPoints;
                    }, 200);
                }
            });
        }

        onMounted(() => {
            dataEventListener();
            updatePointsListener();
            menuEventClickListener();
        });

        return {
            earnFrame,
            userTotalPoints,
            showEarnFrame,
            hideEarnFrame,
            userName,
            walletAddress,
            totalPrizePools,
            dailyCheckinRef,
            dailyCheckinBtnClick,
            dataCheckin,
            shouldShowLine,
            isChecked,
            totalDayChecked,
            getWeekRange,
            totalDefinTaskRemaining,
            totalPointsRemaining,
            dailyTasksClick,
            earnDashboardRef,
            dailyTasksRef,
            userTotalXdefin,
            taskListData,
            taskRefs,
            nextPointCheckin,
            isTodayChecked,
            lastDayCheckedPoints,
            connectPhoneClick,
            submitTask,
            definTasksBtn,
            partnerTasksBtn,
            totalParnerTaskRemaining
        }
    }
}
</script>

<style scoped>
/* .box-shadow {
    filter: drop-shadow(0 0 5px #141414);
    -webkit-filter: drop-shadow(0 0 5px #141414);
} */

.border-custom {
    filter: drop-shadow(0 0 2px #CDFB2D);
    -webkit-filter: drop-shadow(0 0 2px #CDFB2D);
}

.bullet-point::before {
    content: '• ';
    color: #D9D9D9;
    font-weight: bold;
    font-size: 2vh;
    padding-right: 1.5px;
}
</style>