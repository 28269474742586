import { createApp } from 'vue'
import App from './App.vue'
import './assets/main.css'
import { createPinia } from 'pinia'
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import VueConfetti from 'vue-confetti';

const app = createApp(App)
app.use(createPinia())
app.use(Toast, {
    position: POSITION.TOP_CENTER,
    hideProgressBar: true,
});
app.use(VueConfetti);
app.mount('#app')

