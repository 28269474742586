<template>
    <div ref="leaderboardFrame"
        class="relative h-full w-full flex justify-center items-center transition-all duration-700 opacity-0 hidden">
        <img src="../assets/img/background-main.png" class="h-full w-full object-cover">
        <div class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
            <div class="w-[90%] h-[80%] flex justify-start items-start">
                <div class="w-full h-[95%] flex flex-col">
                    <div class="w-full h-[5%] flex">

                        <div @click="changeBoardBtnClick(1)" class="relative flex-1 h-full">
                            <div ref="backgroundPoints"
                                class="absolute w-full h-full top-0 left-0 rounded-md bg-[#CDFB2D] transition-all duration-300">
                            </div>
                            <div class="absolute w-full h-full top-0 left-0 flex justify-center items-center">
                                <h1 ref="textPoints"
                                    class="font-montserrat text-[#141414] text-[1.8vh] font-[700] leading-none transition-all duration-300">
                                    $xDEF</h1>
                            </div>
                        </div>

                        <div @click="changeBoardBtnClick(2)" class="relative flex-1 h-full">
                            <div ref="backgroundRef"
                                class="absolute w-full h-full top-0 left-0 rounded-md bg-[#CDFB2D] transition-all duration-300 opacity-0">
                            </div>
                            <div class="absolute w-full h-full top-0 left-0 flex justify-center items-center">
                                <h1 ref="textRef"
                                    class="font-montserrat text-[#D9D9D9] text-[1.8vh] font-[700] leading-none transition-all duration-300">
                                    Ref</h1>
                            </div>
                        </div>

                        <div @click="changeBoardBtnClick(3)" class="relative flex-1 h-full">
                            <div ref="backgroundNode"
                                class="absolute w-full h-full top-0 left-0 rounded-md bg-[#CDFB2D] transition-all duration-300 opacity-0">
                            </div>
                            <div class="absolute w-full h-full top-0 left-0 flex justify-center items-center">
                                <h1 ref="textNode"
                                    class="font-montserrat text-[#D9D9D9] text-[1.8vh] font-[700] leading-none transition-all duration-300">
                                    $veDEF</h1>
                            </div>
                        </div>

                    </div>

                    <div ref="tabLeaderboard"
                        class="w-full h-[85%] mt-[3%] flex flex-col overflow-y-scroll no-scrollbar transition-all duration-300">

                        <div v-if="dataLeaderboardDisplay[0]" class="w-full aspect-[2/1] flex flex-col">
                            <div class="w-full h-[70%] flex justify-center items-center">
                                <img v-if="boardActiveIndex == 1" src="../assets/img/leaderboard/cup-points.webp"
                                    class="h-[90%] translate-y-[18%] max-h-[150px]">
                                <img v-if="boardActiveIndex == 2" src="../assets/img/leaderboard/cup-ref.webp"
                                    class="h-[90%] translate-y-[18%] max-h-[150px]">
                                <img v-if="boardActiveIndex == 3" src="../assets/img/leaderboard/cup-node.webp"
                                    class="h-[90%] translate-y-[18%] max-h-[150px]">
                            </div>
                            <div class="relative w-full h-[30%] flex justify-center items-center">
                                <img src="../assets/img/leaderboard/background-top1.webp" class="w-full">
                                <div class="absolute w-full h-full top-0 left-0 flex justify-center items-center">
                                    <div class="w-[90%] h-[40%] flex">
                                        <div class="w-[40%] h-full flex justify-start items-center gap-[5%]">
                                            <img src="../assets/img/points.webp" class="h-[75%]">
                                            <h1
                                                class="font-montserrat text-[#CDFB2D] text-[1.8vh] font-[700] leading-none">
                                                {{ dataLeaderboardDisplay[0].teleAccount }}</h1>
                                        </div>
                                        <div class="w-[60%] h-full flex justify-end items-center">
                                            <h1
                                                class="font-montserrat text-[#CDFB2D] text-[4vh] font-[700] leading-none">
                                                {{ dataLeaderboardDisplay[0].totalPoint ?
                            dataLeaderboardDisplay[0].totalPoint :
                            dataLeaderboardDisplay[0].invitedFriendsCount }}</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="dataLeaderboardDisplay[1]" class="w-full aspect-[6/1] flex mt-[5%]">
                            <div class="h-full aspect-square flex justify-center items-center">
                                <img src="../assets/img/leaderboard/cup-sliver.webp" class="h-[90%] max-h-[80px]">
                            </div>
                            <div class="w-full h-full flex justify-end items-center">
                                <div class="relative w-full h-[70%] flex justify-center items-center">
                                    <img src="../assets/img/leaderboard/background-top2.webp"
                                        class="w-full scale-[1.5] origin-right -translate-x-[1px]">
                                    <div class="absolute top-0 left-0 w-full h-full flex">
                                        <div class="h-full w-[50%] flex justify-start items-center gap-[5%]">
                                            <img src="../assets/img/points.webp" class="h-[38%]">
                                            <h1
                                                class="font-montserrat text-[#CDFB2D] text-[1.8vh] font-[700] leading-none">
                                                {{ dataLeaderboardDisplay[1].teleAccount }}</h1>
                                        </div>
                                        <div class="h-full w-[50%] flex justify-end items-center">
                                            <h1
                                                class="font-montserrat text-[#CDFB2D] text-[3vh] font-[700] leading-none mr-[12.5%]">
                                                {{ dataLeaderboardDisplay[0].totalPoint ?
                            dataLeaderboardDisplay[1].totalPoint :
                            dataLeaderboardDisplay[1].invitedFriendsCount }}</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="dataLeaderboardDisplay[2]" class="w-full aspect-[6/1] flex mt-[2%] mb-[2%]">
                            <div class="h-full aspect-square flex justify-center items-center">
                                <img src="../assets/img/leaderboard/cup-bronze.webp" class="h-[90%] max-h-[80px]">
                            </div>
                            <div class="w-full h-full flex justify-end items-center">
                                <div class="relative w-full h-[70%] flex justify-center items-center">
                                    <img src="../assets/img/leaderboard/background-top2.webp"
                                        class="w-full scale-[1.5] origin-right -translate-x-[1px]">
                                    <div class="absolute top-0 left-0 w-full h-full flex">
                                        <div class="h-full w-[50%] flex justify-start items-center gap-[5%]">
                                            <img src="../assets/img/points.webp" class="h-[38%]">
                                            <h1
                                                class="font-montserrat text-[#CDFB2D] text-[1.8vh] font-[700] leading-none">
                                                {{ dataLeaderboardDisplay[2].teleAccount }}</h1>
                                        </div>
                                        <div class="h-full w-[50%] flex justify-end items-center">
                                            <h1
                                                class="font-montserrat text-[#CDFB2D] text-[3vh] font-[700] leading-none mr-[12.5%]">
                                                {{ dataLeaderboardDisplay[0].totalPoint ?
                            dataLeaderboardDisplay[2].totalPoint :
                            dataLeaderboardDisplay[2].invitedFriendsCount }}</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div v-for="rank in dataLeaderboardDisplay.filter(rank => rank.rank > 3)" :key="rank"
                            class="w-full aspect-[15/1] mt-[1%] flex">
                            <div class="w-[60%] h-full flex justify-start items-center gap-[4%]">
                                <h1
                                    class="font-montserrat text-[#CDFB2D] text-[1.8vh] font-[700] leading-none ml-[5%] w-[10%] text-center">
                                    {{ rank.rank }}</h1>
                                <img src="../assets/img/points.webp" class="h-[70%] max-h-[22px]">
                                <h1 class="font-montserrat text-[#CDFB2D] text-[1.8vh] font-[700] leading-none">
                                    {{ rank.teleAccount }}</h1>
                            </div>
                            <div class="w-[40%] h-full flex justify-end items-center">
                                <h1
                                    class="font-montserrat text-[#CDFB2D] text-[1.8vh] font-[700] leading-none mr-[13%]">
                                    {{ rank.totalPoint ?? rank.invitedFriendsCount ?? 0 }}</h1>
                            </div>
                        </div>



                    </div>

                    <div ref="yourRankLeaderboard"
                        class="w-full h-[5%] mt-[3%] border rounded-xl border-[#d9d9d936] bg-[#52525217] flex justify-center transition-all duration-300">
                        <div v-if="yourRankData" class="w-[90%] h-full flex">
                            <div class="h-full w-[70%] flex justify-start items-center gap-[3%]">
                                <h1 class="font-montserrat text-[#D9D9D9] text-[1.8vh] font-[700] leading-none">
                                    Your rank</h1>
                                <h1 class="font-montserrat text-[#CDFB2D] text-[1.8vh] font-[700] leading-none">
                                    {{ yourRankData.rank ?? '???' }}</h1>
                                <img src="../assets/img/points.webp" class="h-[70%]">
                                <h1 class="font-montserrat text-[#CDFB2D] text-[1.8vh] font-[700] leading-none">
                                    {{ yourRankData.teleAccount ?? state.getUserInfoFromTelegram().username }}</h1>
                            </div>
                            <div class="h-full w-[30%] flex justify-end items-center">
                                <h1 class="font-montserrat text-[#CDFB2D] text-[1.8vh] font-[700] leading-none">
                                    {{ yourRankData.totalPoint ?? yourRankData.invitedFriendsCount ?? 0 }}</h1>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useState } from '../assets/controller/state';
import * as Utils from '../assets/controller/utilities';
import * as definAPI from '../assets/controller/api';

export default {
    name: 'LeaderboardDefin',

    setup(props, { expose }) {
        const state = useState();
        const leaderboardFrame = ref(null);
        const boardActiveIndex = ref(1);
        const backgroundPoints = ref(null);
        const textPoints = ref(null);
        const backgroundRef = ref(null);
        const textRef = ref(null);
        const backgroundNode = ref(null);
        const textNode = ref(null);
        const yourRankData = ref();
        const dataLeaderboardDisplay = ref([]);
        const tabLeaderboard = ref(null);
        const yourRankLeaderboard = ref(null);
        const pointsData = ref([])
        const refData = ref([])
        const nodeData = ref([])
        const yourPointData = ref({});
        const yourRefData = ref({});
        const yourNodeData = ref({});
        const boards = [
            { index: 1, background: backgroundPoints, text: textPoints, data: pointsData, yourData: yourPointData },
            { index: 2, background: backgroundRef, text: textRef, data: refData, yourData: yourRefData },
            { index: 3, background: backgroundNode, text: textNode, data: nodeData, yourData: yourNodeData }
        ];

        expose({
            leaderboardFrame,
            showLeaderboardFrame,
            hideLeaderboardFrame
        });

        function showLeaderboardFrame() {
            leaderboardFrame.value.classList.remove('hidden');
            setTimeout(() => {
                leaderboardFrame.value.classList.remove('opacity-0');
            }, 10);
        }

        function hideLeaderboardFrame() {
            leaderboardFrame.value.classList.add('opacity-0');
            setTimeout(() => {
                resetBoardMenuBtn();
                boardActiveIndex.value = 1;
                activeBoardMenuBtn(1);
            }, 400);
            setTimeout(() => {
                leaderboardFrame.value.classList.add('hidden');
            }, 700);

        }

        function changeBoardBtnClick(index) {
            if (index == boardActiveIndex.value) {
                return;
            }
            if (index == 3) {
                Utils.toastInfo('We are cooking it!');
                return;
            }
            resetBoardMenuBtn();
            activeBoardMenuBtn(index);
        }

        function resetBoardMenuBtn() {
            const activeBoard = boards.find(board => boardActiveIndex.value === board.index);
            if (activeBoard) {
                activeBoard.background.value.classList.add('opacity-0');
                activeBoard.text.value.classList.remove('text-[#141414]');
                activeBoard.text.value.classList.add('text-[#D9D9D9]');
            }
        }

        function activeBoardMenuBtn(index) {
            const activeBoard = boards.find(board => index === board.index);
            activeBoard.background.value.classList.remove('opacity-0');
            activeBoard.text.value.classList.remove('text-[#D9D9D9]');
            activeBoard.text.value.classList.add('text-[#141414]');
            tabLeaderboard.value.classList.add('opacity-0');
            yourRankLeaderboard.value.classList.add('opacity-0');
            setTimeout(() => {
                boardActiveIndex.value = index;
                yourRankData.value = activeBoard.yourData.value;
                dataLeaderboardDisplay.value = activeBoard.data.value;
                tabLeaderboard.value.classList.remove('opacity-0');
                yourRankLeaderboard.value.classList.remove('opacity-0');
            }, 300);
        }

        function dataEventListener() {
            document.addEventListener("dataEvent", async (event) => {
                const data = event.detail.message;
                if (data.callback === -1) {
                    const userId = state.getUserInfoFromServer().userId;
                    const { pointsRank, invitationsRank, userRankPoint, userRankInvitation } = await definAPI.getLeaderboard(userId);
                    if (pointsRank.success && invitationsRank.success && userRankPoint.success && userRankInvitation.success) {
                        pointsData.value = pointsRank.data;
                        yourPointData.value = userRankPoint.data;
                        refData.value = invitationsRank.data;
                        yourRefData.value = userRankInvitation.data;
                    }
                }
            });
        }

        onMounted(() => {
            dataEventListener();
        });

        return {
            state,
            leaderboardFrame,
            showLeaderboardFrame,
            hideLeaderboardFrame,
            changeBoardBtnClick,
            boardActiveIndex,
            backgroundPoints,
            textPoints,
            backgroundRef,
            textRef,
            backgroundNode,
            textNode,
            yourRankData,
            dataLeaderboardDisplay,
            pointsData,
            refData,
            nodeData,
            tabLeaderboard,
            yourRankLeaderboard,
            yourPointData,
            yourRefData,
            yourNodeData,
        }
    }
}
</script>

<style scoped></style>