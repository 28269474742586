import axios from 'axios';
import { getDefinKeyFromLocalStorage } from './utilities'
const ORIGINAL_PATH_URL = String(process.env.VUE_APP_API_URL);

function handleError(error) {
    console.log(error);
    return null;
}

async function axiosRequest(method, url, data = {}, params = {}, includeJwt = false) {
    try {
        const headers = {
            'accept': '*/*',
            'Content-Type': 'application/json',
        };

        if (includeJwt) {
            const jwt = getDefinKeyFromLocalStorage();
            if (jwt) {
                headers['Authorization'] = `Bearer ${jwt}`;
            }
        }

        const response = await axios({
            method,
            url: `${ORIGINAL_PATH_URL}${url}`,
            data: method === 'post' ? data : undefined,
            params: method === 'get' ? params : undefined,
            headers
        });

        return response.data;
    } catch (error) {
        return handleError(error);
    }
}

export async function startBot(teleAccountName, teleAccountId) {
    return axiosRequest('post', 'user/start-bot', { isPremium: true, teleAccountName: String(teleAccountName), teleAccountId: teleAccountId });
}

export async function teleAuth(accessToken, premium = false) {
    return axiosRequest('post', 'user/tele-auth', { isPremium: premium, data: accessToken });
}

export async function userInfo() {
    return axiosRequest('get', 'user/info', {}, {}, true);
}

export async function userInvitationList(userId) {
    return axiosRequest('get', 'invitation/invitees', {}, { userId: userId }, true);
}

export async function getMissions(userId) {
    return axiosRequest('get', 'tasks/missions', {}, { userId: userId }, true);
}

export async function getCheckins(userId) {
    return axiosRequest('get', 'tasks/checkins', {}, { userId: userId }, true);
}

export async function getHistoryTask(userId) {
    return axiosRequest('get', 'tasks/history', {}, { userId: userId }, true);
}

export async function createPassword(userId, password) {
    return axiosRequest('post', 'tasks/submit/create_password', { userId: userId, password: password }, {}, true);
}

export async function submitTeleAge(userId, id) {
    return axiosRequest('post', 'tasks/submit/tele_age', { userId: userId, id: id }, {}, true);
}

export async function submitCheckin(taskId, userId) {
    return axiosRequest('post', 'tasks/submit/checkin', { taskId: taskId, userId: userId }, {}, true);
}

export async function submitTelePremium(userId) {
    return axiosRequest('post', 'tasks/submit/tele_account', { userId: userId }, {}, true);
}

export async function submitInvite(taskId, userId, invitationCode) {
    return axiosRequest('post', 'tasks/submit/invite', { taskId: taskId, userId: userId, invitationCode: invitationCode }, {}, true);
}

export async function submitSocial(taskId, userId, source, destination) {
    return axiosRequest('post', 'tasks/submit/social', { taskId: taskId, userId: userId, source: source, destination: destination }, {}, true);
}

export async function getUserRankInvitation(userId) {
    return axiosRequest('get', 'leaderboard/invitation-rank', {}, { userId: userId }, true);
}

export async function getUserRankPoints(userId) {
    return axiosRequest('get', 'leaderboard/user-rank', {}, { userId: userId }, true);
}

export async function getInvitationsRank(userId) {
    return axiosRequest('get', 'leaderboard/invitations', {}, { userId: userId }, true);
}

export async function getPointsRank(userId) {
    return axiosRequest('get', 'leaderboard/users', {}, { userId: userId }, true);
}

export async function getLeaderboard(userId) {
    try {
        const [
            pointsRank, invitationsRank, userRankPoint,
            userRankInvitation] = await Promise.all([
                getPointsRank(userId),
                getInvitationsRank(userId),
                getUserRankPoints(userId),
                getUserRankInvitation(userId),
            ]);
        return {
            pointsRank: pointsRank, invitationsRank: invitationsRank, userRankPoint: userRankPoint,
            userRankInvitation: userRankInvitation
        };
    } catch (error) {
        return handleError(error);
    }
}

export async function submitCheckingAccount(userId, id) {
    try {
        const [telePremium, teleCheckingAge] = await Promise.all([
            submitTelePremium(userId),
            submitTeleAge(userId, id)
        ])
        return { telePremium: telePremium, teleCheckingAge: teleCheckingAge };
    } catch (error) {
        return handleError(error);
    }
}
