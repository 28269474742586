<template>
    <div ref="registersFrame"
        class="relative h-full w-full flex justify-center items-center transition-all duration-700 hidden">
        <div ref="bgRegistersRef"
            class="absolute top-0 left-0 w-full h-full transition-all duration-700 scale-125 opacity-0">
            <img src="../assets/img/registers/background.webp" class="h-full w-full object-cover">
        </div>
        <div class="relative h-full w-full flex flex-col justify-start items-center">
            <div @click="hideKeyBoard" class="h-2/5 w-full flex justify-center items-end">
                <div ref="logo"
                    class="w-[22vh] h-fit flex flex-col justify-center items-center transition-all duration-700 translate-y-[60%] pointer-events-none">
                    <img src="../assets/img/logo.webp" class="w-full box-shadow-logo">
                    <h1 class="font-azonix text-[#D9D9D9] text-[6.6vh]">DEFIN</h1>
                </div>
            </div>
            <div ref="statusSignUpRef"
                class="h-3/5 w-full flex flex-col gap-[2%] justify-center items-center transition-all duration-700 -translate-y-[50%] opacity-0">
                <h1 class="font-montserrat text-[#D9D9D9] text-center font-[700] text-[2.5vh]">Sign up with <br>
                    telegram account</h1>
                <span class="font-montserrat text-[#D9D9D9] text-center font-[400] text-[1.5vh]">Almost there... just a
                    few more seconds.</span>

                <svg ref="loadingRef" class="animate-spin mt-[5%] transition-all duration-300 opacity-0"
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12ZM2.1 12C2.1 17.4676 6.53238 21.9 12 21.9C17.4676 21.9 21.9 17.4676 21.9 12C21.9 6.53238 17.4676 2.1 12 2.1C6.53238 2.1 2.1 6.53238 2.1 12Z"
                        stroke="url(#paint0_linear_1095_30708)" stroke-width="3" />
                    <defs>
                        <linearGradient id="paint0_linear_1095_30708" x1="4.5" y1="3" x2="20.5" y2="19"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#CDFB2D" />
                            <stop offset="1" stop-color="#39460D" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div ref="passwordFormInputRef"
                class="h-3/5 w-full flex flex-col justify-start items-center transition-all duration-700 opacity-0 hidden">
                <div class="w-full h-[10%] flex justify-center items-center mb-[2%]">
                    <h1 class="font-montserrat text-[#D9D9D9] text-center font-[700] text-[2.5vh]">Create your password
                    </h1>
                </div>
                <div class="w-full h-[15%] flex justify-center items-center mb-[5%]">
                    <div class="w-[80%] h-full flex flex-col justify-between items-start">
                        <span class="font-montserrat text-[#D9D9D9] text-center font-[400] text-[1.5vh]">Enter your
                            password</span>
                        <div class="relative w-full h-[60%]">
                            <input @input="handlePasswordInput" v-model="passwordInput" ref="passwordInputRef"
                                type="password"
                                class="font-montserrat w-full h-full rounded-2xl border border-white outline-none bg-[#1b1b1b40] text-[#D9D9D9] text-[2vh] box-border pl-[2%] pr-[10%]">
                            <div class="absolute top-0 right-0 h-full aspect-square flex justify-center items-center">
                                <img @click="passwordToggle" v-if="!pwShow" src="../assets/img/registers/hint.webp"
                                    class="w-[50%]">
                                <img @click="passwordToggle" v-else src="../assets/img/registers/show.webp"
                                    class="w-[50%]">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-full h-[15%] flex justify-center items-center">
                    <div class="w-[80%] h-full flex flex-col justify-between items-start">
                        <span class="font-montserrat text-[#D9D9D9] text-center font-[400] text-[1.5vh]">Re-enter your
                            password</span>
                        <div class="relative w-full h-[60%]">
                            <input ref="passwordReInputRef" v-model="rePasswordInput" @input="handleRePasswordInput"
                                type="password"
                                class="font-montserrat w-full h-full rounded-2xl border border-white outline-none bg-[#1b1b1b40] text-[#D9D9D9] text-[2vh] box-border pl-[2%] pr-[10%]">
                            <div class="absolute top-0 right-0 h-full aspect-square flex justify-center items-center">
                                <img @click="rePasswordToggle" v-if="!rpwShow" src="../assets/img/registers/hint.webp"
                                    class="w-[50%]">
                                <img @click="rePasswordToggle" v-else src="../assets/img/registers/show.webp"
                                    class="w-[50%]">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-full h-[30%] flex justify-center items-center">
                    <div class="w-[80%] h-full flex flex-col justify-center items-start gap-[1%]">
                        <div class="w-full h-[12%] flex justify-start items-center gap-[3%]">
                            <div class="h-[80%] aspect-square flex justify-center items-center">
                                <img v-if="!oneLowercaseRef" src="../assets/img/registers/error.webp" class="w-full">
                                <img v-else src="../assets/img/registers/ok.webp" class="w-full">
                            </div>
                            <span v-if="!oneLowercaseRef"
                                class="font-montserrat text-[#E33629] text-center font-[400] text-[1.5vh]">One lowercase
                                charater</span>
                            <span v-else class="font-montserrat text-[#CDFB2D] text-center font-[400] text-[1.5vh]">One
                                lowercase charater</span>
                        </div>
                        <div class="w-full h-[12%] flex justify-start items-center gap-[3%]">
                            <div class="h-[80%] aspect-square flex justify-center items-center">
                                <img v-if="!oneUppercaseRef" src="../assets/img/registers/error.webp" class="w-full">
                                <img v-else src="../assets/img/registers/ok.webp" class="w-full">
                            </div>
                            <span v-if="!oneUppercaseRef"
                                class="font-montserrat text-[#E33629] text-center font-[400] text-[1.5vh]">One uppercase
                                character</span>
                            <span v-else class="font-montserrat text-[#CDFB2D] text-center font-[400] text-[1.5vh]">One
                                uppercase character</span>
                        </div>
                        <div class="w-full h-[12%] flex justify-start items-center gap-[3%]">
                            <div class="h-[80%] aspect-square flex justify-center items-center">
                                <img v-if="!oneNumbercaseRef" src="../assets/img/registers/error.webp" class="w-full">
                                <img v-else src="../assets/img/registers/ok.webp" class="w-full">
                            </div>
                            <span v-if="!oneNumbercaseRef"
                                class="font-montserrat text-[#E33629] text-center font-[400] text-[1.5vh]">One
                                number</span>
                            <span v-else class="font-montserrat text-[#CDFB2D] text-center font-[400] text-[1.5vh]">One
                                number</span>
                        </div>
                        <div class="w-full h-[12%] flex justify-start items-center gap-[3%]">
                            <div class="h-[80%] aspect-square flex justify-center items-center">
                                <img v-if="!oneSpecialcaseRef" src="../assets/img/registers/error.webp" class="w-full">
                                <img v-else src="../assets/img/registers/ok.webp" class="w-full">
                            </div>
                            <span v-if="!oneSpecialcaseRef"
                                class="font-montserrat text-[#E33629] text-center font-[400] text-[1.5vh]">One special
                                character</span>
                            <span v-else class="font-montserrat text-[#CDFB2D] text-center font-[400] text-[1.5vh]">One
                                special character</span>
                        </div>
                        <div class="w-full h-[12%] flex justify-start items-center gap-[3%]">
                            <div class="h-[80%] aspect-square flex justify-center items-center">
                                <img v-if="!minimumCharacterRef" src="../assets/img/registers/error.webp"
                                    class="w-full">
                                <img v-else src="../assets/img/registers/ok.webp" class="w-full">
                            </div>
                            <span v-if="!minimumCharacterRef"
                                class="font-montserrat text-[#E33629] text-center font-[400] text-[1.5vh]">8 character
                                minimum</span>
                            <span v-else class="font-montserrat text-[#CDFB2D] text-center font-[400] text-[1.5vh]">8
                                character minimum</span>
                        </div>
                        <div class="w-full h-[12%] flex justify-start items-center gap-[3%]">
                            <div class="h-[80%] aspect-square flex justify-center items-center">
                                <img v-if="!matchesRef" src="../assets/img/registers/error.webp" class="w-full">
                                <img v-else src="../assets/img/registers/ok.webp" class="w-full">
                            </div>
                            <span v-if="!matchesRef"
                                class="font-montserrat text-[#E33629] text-center font-[400] text-[1.5vh]">Re-enter
                                password matches password</span>
                            <span v-else
                                class="font-montserrat text-[#CDFB2D] text-center font-[400] text-[1.5vh]">Re-enter
                                password matches password</span>
                        </div>
                    </div>
                </div>
                <div class="w-full h-[23%] flex justify-center items-center">
                    <div ref="createPasswordBtnRef" @click="handleCreatePasswordBtnClick" :class="[
                'w-[80%] h-[40%] rounded-2xl flex justify-center items-center transition-all active:scale-90',
                activeBtnRef ? 'border-white bg-[#CDFB2D] box-shadow' : 'border border-white bg-[#1b1b1b40] pointer-events-none'
            ]">
                        <h1 :class="[
                'font-montserrat text-center font-[700] text-[2vh]',
                activeBtnRef ? 'text-[#141414]' : 'text-[#D9D9D9]'
            ]">
                            Create password
                        </h1>
                    </div>
                </div>

            </div>


            <div ref="resultRegistersRef"
                class="h-3/5 w-full flex flex-col justify-start items-center transition-all duration-700 opacity-0 hidden">
                <div class="h-[77%] w-full flex justify-center items-center">
                    <div class="w-[80%] h-full flex flex-col gap-[3%] justify-center items-center">
                        <img v-if="registersStatus" src="../assets/img/registers/ok.webp" class="w-12 h-12">
                        <img v-else src="../assets/img/registers/error.webp" class="w-12 h-12">
                        <h1 v-if="registersStatus"
                            class="font-montserrat text-[#D9D9D9] text-center font-[700] text-[2.5vh]">You are all set
                        </h1>
                        <h1 v-else class="font-montserrat text-[#D9D9D9] text-center font-[700] text-[2.5vh]">Something
                            went wrong
                        </h1>
                        <span v-if="registersStatus"
                            class="font-montserrat text-[#D9D9D9] text-center font-[400] text-[1.5vh]">Thank you for
                            register. <br>
                            Let's experience the app</span>
                        <span v-else class="font-montserrat text-[#D9D9D9] text-center font-[400] text-[1.5vh]">You have
                            failed to create an account. <br> Don’t worry, just try again.</span>
                    </div>
                </div>
                <div class="w-full h-[23%] flex justify-center items-center">
                    <div ref="btnResultRef" @click="handleResultRegistersBtnClick"
                        class="w-[80%] h-[40%] rounded-2xl flex justify-center items-center border-white bg-[#CDFB2D] transition-all active:scale-90 box-shadow">
                        <h1 class="font-montserrat text-[#141414] text-center font-[700] text-[2vh]"> {{ registersStatus
                ? 'Get started' : 'Try again' }}
                        </h1>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>


<script>
import { ref, onMounted } from 'vue';
import { useState } from '../assets/controller/state';
import * as definAPI from '../assets/controller/api'

export default {
    name: 'RegistersDefin',
    setup() {
        const state = useState();
        const logo = ref(null);
        const registersFrame = ref(null);
        const statusSignUpRef = ref(null);
        const loadingRef = ref(null);
        const bgRegistersRef = ref(null);
        const pwShow = ref(false);
        const rpwShow = ref(false);
        const passwordInputRef = ref(null);
        const passwordReInputRef = ref(null);
        const oneLowercaseRef = ref(false);
        const oneUppercaseRef = ref(false);
        const oneNumbercaseRef = ref(false);
        const oneSpecialcaseRef = ref(false);
        const minimumCharacterRef = ref(false);
        const matchesRef = ref(false);
        const activeBtnRef = ref(false);
        const passwordInput = ref(null);
        const rePasswordInput = ref(null);
        const passwordFormInputRef = ref(null);
        const createPasswordBtnRef = ref(null);
        const resultRegistersRef = ref(null);
        const registersStatus = ref(false);
        const btnResultRef = ref(null);

        function waitLoadingFinish(callback) {
            const checkInterval = setInterval(() => {
                if (state.getLoadingState() && !state.getUserInfoFromServer().password) {
                    clearInterval(checkInterval);
                    callback();
                }
                if (state.getLoadingState() && state.getUserInfoFromServer().password) {
                    clearInterval(checkInterval);
                }
            }, 10);
        }

        function showStatusSignUp() {
            //don't show register
            // registersFrame.value.classList.remove('hidden');
            setTimeout(() => {
                logo.value.classList.remove('translate-y-[60%]');
                statusSignUpRef.value.classList.remove('opacity-0');
                statusSignUpRef.value.classList.remove('-translate-y-[50%]');
                bgRegistersRef.value.classList.remove('opacity-0');
                bgRegistersRef.value.classList.remove('scale-125');
            }, 10);
            setTimeout(() => {
                loadingRef.value.classList.remove('opacity-0');
            }, 700);

            // test with no api
            setTimeout(() => {
                showPasswordFormInput();
            }, 3000);
        }

        function showPasswordFormInput() {
            statusSignUpRef.value.classList.add('opacity-0');
            setTimeout(() => {
                bgRegistersRef.value.classList.add('opacity-0');
                setTimeout(() => {
                    bgRegistersRef.value.classList.add('hidden');
                }, 700);
            }, 700);
            setTimeout(() => {
                statusSignUpRef.value.classList.add('hidden');
                setTimeout(() => {
                    passwordFormInputRef.value.classList.remove('hidden');
                    setTimeout(() => {
                        passwordFormInputRef.value.classList.remove('opacity-0');
                    }, 10);
                }, 1000);
            }, 700);
        }

        function reShowPasswordFormInput() {
            resultRegistersRef.value.classList.add('opacity-0');
            setTimeout(() => {
                resultRegistersRef.value.classList.add('hidden');
                passwordFormInputRef.value.classList.remove('hidden');
                passwordFormInputRef.value.classList.remove('pointer-events-none');
                passwordFormInputRef.value.classList.remove('grayscale-[50%]');
                const btnText = createPasswordBtnRef.value.querySelector('h1');
                btnText.textContent = 'Create password'
                setTimeout(() => {
                    passwordFormInputRef.value.classList.remove('opacity-0');
                }, 10);
            }, 700);
        }

        function showResultRegisters() {
            passwordFormInputRef.value.classList.add('opacity-0');
            setTimeout(() => {
                passwordFormInputRef.value.classList.add('hidden');
                resultRegistersRef.value.classList.remove('hidden');
                setTimeout(() => {
                    resultRegistersRef.value.classList.remove('opacity-0');
                }, 10);
            }, 700);
        }

        function passwordToggle() {
            if (pwShow.value) {
                passwordInputRef.value.type = 'password';
            } else {
                passwordInputRef.value.type = 'text';
            }
            pwShow.value = !pwShow.value;
        }

        function rePasswordToggle() {
            if (rpwShow.value) {
                passwordReInputRef.value.type = 'password';
            } else {
                passwordReInputRef.value.type = 'text';
            }
            rpwShow.value = !rpwShow.value;
        }

        function handlePasswordInput(event) {
            oneLowercaseRef.value = /[a-z]/.test(event.target.value);
            oneUppercaseRef.value = /[A-Z]/.test(event.target.value);
            oneNumbercaseRef.value = /\d/.test(event.target.value);
            oneSpecialcaseRef.value = /[!@#$%^&*(),.?":{}|<>]/.test(event.target.value);
            minimumCharacterRef.value = event.target.value.length >= 8;
            matchesRef.value = rePasswordInput.value === event.target.value;
        }

        function handleRePasswordInput(event) {
            if (!event.target.value) {
                return;
            }
            matchesRef.value = passwordInput.value === event.target.value;
            if (matchesRef.value && oneLowercaseRef.value && oneUppercaseRef.value
                && oneNumbercaseRef.value && oneSpecialcaseRef.value && minimumCharacterRef.value
            ) {
                activeBtnRef.value = true;
            } else {
                activeBtnRef.value = false;
            }
        }

        async function handleCreatePasswordBtnClick() {
            passwordFormInputRef.value.classList.add('pointer-events-none');
            passwordFormInputRef.value.classList.add('grayscale-[50%]');
            const btnText = createPasswordBtnRef.value.querySelector('h1');
            setTimeout(() => {
                btnText.textContent = 'Creating password...'
            }, 300);

            const createPasswordResult = await definAPI.createPassword(state.getUserInfoFromServer().userId, passwordInput.value);
            registersStatus.value = createPasswordResult.success;

            setTimeout(() => {
                showResultRegisters();
            }, 1000);
        }

        async function handleResultRegistersBtnClick() {
            if (!registersStatus.value) {
                reShowPasswordFormInput();
            } else {
                registersFrame.value.classList.add('opacity-0');
                setTimeout(async () => {
                    const updateUserInfoFromServer = await definAPI.userInfo(state.getUserInfoFromTelegram().id)
                    state.setUserInfoFromServer(updateUserInfoFromServer.data);
                    registersFrame.value.classList.add('hidden');
                    state.setRegisterState(true);
                }, 700);
            }
        }

        function hideKeyBoard() {
            const activeElement = document.activeElement;
            if (activeElement && activeElement.tagName === 'INPUT') {
                activeElement.blur();
            }
        }

        // function keyboardEventListener() {
        //     document.querySelector('input').addEventListener('focus', function () {
        //         registersFrame.value.classList.add('-translate-y-[20vh]');
        //     });
        //     document.querySelector('input').addEventListener('blur', function () {
        //         registersFrame.value.classList.remove('-translate-y-[20vh]');
        //     });
        // }

        onMounted(() => {
            // keyboardEventListener();
            waitLoadingFinish(() => {
                showStatusSignUp();
            });
        });

        return {
            logo,
            registersFrame,
            statusSignUpRef,
            loadingRef,
            bgRegistersRef,
            pwShow,
            rpwShow,
            oneLowercaseRef,
            oneUppercaseRef,
            oneNumbercaseRef,
            oneSpecialcaseRef,
            minimumCharacterRef,
            matchesRef,
            activeBtnRef,
            passwordInputRef,
            passwordReInputRef,
            passwordToggle,
            rePasswordToggle,
            handlePasswordInput,
            passwordInput,
            rePasswordInput,
            handleRePasswordInput,
            passwordFormInputRef,
            createPasswordBtnRef,
            handleCreatePasswordBtnClick,
            resultRegistersRef,
            registersStatus,
            btnResultRef,
            showResultRegisters,
            handleResultRegistersBtnClick,
            hideKeyBoard
        }
    }
}
</script>

<style scoped>
.box-shadow {
    box-shadow: 0px 0px 15px 0px #CDFB2D;
}
</style>