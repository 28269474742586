<template>
    <div ref="dashBoardFrame"
        class="relative h-full w-full flex justify-center items-center transition-all duration-700 opacity-0 hidden">
        <img src="../assets/img/background-main.png" class="h-full w-full object-cover">
        <div class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
            <div class="w-[90%] h-[80%] flex justify-start items-start">
                <div class="w-full h-[95%] flex flex-col justify-start items-center overflow-y-scroll no-scrollbar">
                    <div class="w-full aspect-[16/9] min-h-[200px]">
                        <swiper :pagination="{
                            dynamicBullets: false, clickable: true
                        }" :modules="modules" :loop="true" :autoplay="{
                            delay: 3000, disableOnInteraction: false
                        }" :speed="1000" :slides-per-view="1">
                            <swiper-slide v-for="event in eventData" :key="event">
                                <div class="w-full h-full flex justify-start items-start">
                                    <div class="w-full aspect-[21/9] rounded-lg overflow-hidden">
                                        <img :src="event.img" class="h-full w-full">
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                    <div class="relative w-full h-fit border border-[#d9d9d97e] rounded-lg mt-[2%]">
                        <img src="../assets/img/dashboard/background-balance.png" class="w-full rounded-lg">
                        <div
                            class="absolute top-0 left-0 w-full h-full flex flex-col gap-[5%] justify-center items-center ">
                            <h1 class="font-montserrat text-[#D9D9D9] font-[500] leading-none text-[2vh] mt-[1%]">
                                Total $xDEF</h1>
                            <h1 class="font-montserrat text-[#CDFB2D] font-[700] leading-none text-[4vh]">{{ userTotalPoints }}</h1>
                        </div>
                    </div>
                    <div class="w-full aspect-[6/1] flex justify-between items-center mt-[10%]">
                        <div class="relative h-full w-[48%]">
                            <img src="../assets/img/dashboard/background-main-button.webp" class="w-full">
                            <div class="absolute top-0 left-0 w-full h-full flex justify-center items-end">
                                <h1 class="font-montserrat text-[#D9D9D9] font-[700] leading-none text-[2vh] mb-[3%]">
                                    Channel</h1>
                            </div>
                            <div class="absolute bottom-[36%] left-0 w-full h-full flex justify-center items-center">
                                <div class="w-[30%] aspect-square flex justify-center items-center">
                                    <img @click="channelClick" src="../assets/img/dashboard/telechannel.webp"
                                        class="w-[85%] transition-all active:scale-90">
                                </div>
                            </div>
                        </div>
                        <div class="relative h-full w-[48%]">
                            <img src="../assets/img/dashboard/background-main-button.webp" class="w-full">
                            <div class="absolute top-0 left-0 w-full h-full flex justify-center items-end">
                                <h1 class="font-montserrat text-[#D9D9D9] font-[700] leading-none text-[2vh] mb-[3%]">
                                    Group</h1>
                            </div>
                            <div class="absolute bottom-[36%] left-0 w-full h-full flex justify-center items-center">
                                <div class="w-[30%] aspect-square flex justify-center items-center">
                                    <img @click="groupClick" src="../assets/img/dashboard/telegroup.webp"
                                        class="w-[85%] transition-all active:scale-90">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-full aspect-[6/1] flex justify-between items-center mt-[10%]">
                        <div class="relative h-full w-[48%]">
                            <img src="../assets/img/dashboard/background-main-button.webp" class="w-full">
                            <div class="absolute top-0 left-0 w-full h-full flex justify-center items-end">
                                <h1 class="font-montserrat text-[#D9D9D9] font-[700] leading-none text-[2vh] mb-[3%]">
                                    Twitter</h1>
                            </div>
                            <div class="absolute bottom-[36%] left-0 w-full h-full flex justify-center items-center">
                                <div class="w-[30%] aspect-square flex justify-center items-center">
                                    <img @click="twitterClick" src="../assets/img/dashboard/x.webp"
                                        class="w-[85%] transition-all active:scale-90">
                                </div>
                            </div>
                        </div>
                        <div class="relative h-full w-[48%]">
                            <img src="../assets/img/dashboard/background-main-button.webp" class="w-full">
                            <div class="absolute top-0 left-0 w-full h-full flex justify-center items-end">
                                <h1 class="font-montserrat text-[#D9D9D9] font-[700] leading-none text-[2vh] mb-[3%]">
                                    Website</h1>
                            </div>
                            <div class="absolute bottom-[36%] left-0 w-full h-full flex justify-center items-center">
                                <div class="w-[30%] aspect-square flex justify-center items-center">
                                    <img @click="websiteClick" src="../assets/img/dashboard/btn-dapp.webp" class="w-[85%] active:scale-90">
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="../assets/img/dashboard/line.webp" class="w-full mt-[10%] mb-[8%]">
                    <div class="w-full aspect-[9/1] flex justify-start items-center">
                        <div class="h-full aspect-square">
                            <img src="../assets/img/dashboard/history.webp" class="h-full max-h-[50px]">
                        </div>
                        <div class="h-full w-full flex flex-col justify-center items-start gap-[10%]">
                            <h1 class="font-montserrat text-[#D9D9D9] font-[700] leading-none text-[2vh] ml-[3%]">Your
                                point history</h1>
                            <div class="h-fit w-full flex justify-start items-center gap-[2%]">
                                <h1 class="font-montserrat text-[#D9D9D9] font-[400] leading-none text-[1.6vh] ml-[3%]">
                                    Total tasks completed:</h1>
                                <h1 class="font-montserrat text-[#CDFB2D] font-[700] leading-none text-[1.6vh]">{{
                            pointHistory.length }}</h1>
                            </div>
                        </div>
                    </div>
                    <div v-for="history in pointHistory" :key="history"
                        class="w-full aspect-[10/1] flex justify-center items-center mt-[2%]">
                        <div class="w-[90%] h-full flex justify-center items-center">
                            <div class="w-[70%] h-full flex justify-start items-center">
                                <img src="../assets/img/points.webp" class="h-[60%] max-h-[40px]">
                                <h1 class="font-montserrat text-[#D9D9D9] font-[400] leading-none text-[1.6vh] ml-[5%]">
                                    {{ history.taskName }}</h1>
                            </div>
                            <div class="w-[30%] h-full flex justify-end items-center">
                                <h1 class="font-montserrat text-[#CDFB2D] font-[700] leading-none text-[1.6vh]">+ {{
                                    history.point }}</h1>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useState } from '../assets/controller/state';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import * as definAPI from '../assets/controller/api'

export default {
    name: 'DashBoardDefin',
    components: {
        Swiper,
        SwiperSlide,
    },
    setup(props, { expose }) {
        const state = useState();
        const dashBoardFrame = ref(null);
        const eventData = ref([
            { img: require('../assets/img/dashboard/Event1.webp') },
            { img: require('../assets/img/dashboard/Event2.webp') },
            { img: require('../assets/img/dashboard/Event3.webp') },
            { img: require('../assets/img/dashboard/Event4.webp') },
            { img: require('../assets/img/dashboard/Event5.webp') },
        ]);
        const userBalance = ref();
        const userTotalPoints = ref(0);
        const pointHistory = ref([]);

        expose({
            dashBoardFrame,
            showDashboard,
            hideDashboard
        });

        function waitLoadingFinish(callback) {
            const checkInterval = setInterval(() => {
                if (state.getLoadingState() &&
                    state.getUserInfoFromServer().teleAge) {
                    clearInterval(checkInterval);
                    callback();
                }
            }, 10);
        }

        function showDashboard() {
            userBalance.value = state.getUserInfoFromServer().balance ? state.getUserInfoFromServer().balance : 0;
            userTotalPoints.value = state.getUserInfoFromServer().totalPoints;
            dashBoardFrame.value.classList.remove('hidden');
            setTimeout(() => {
                dashBoardFrame.value.classList.remove('opacity-0');
                setTimeout(() => {
                    state.setDashboardState(true);
                }, 700);
            }, 10);
        }

        function hideDashboard() {
            dashBoardFrame.value.classList.add('opacity-0');
            setTimeout(() => {
                dashBoardFrame.value.classList.add('hidden');
            }, 700);
        }

        function channelClick() {
            state.openLink('https://t.me/defin_ann');
        }

        function groupClick() {
            state.openLink('https://t.me/defin_official');
        }

        function twitterClick() {
            state.openLink('https://x.com/DeFIN_app');
        }

        function websiteClick() {
            state.openLink('https://defin.ai');
        }

        function dataEventListener() {
            document.addEventListener("dataEvent", async (event) => {
                const data = event.detail.message;
                if (data.callback === -1 || data.callback === 1) {
                    const userId = state.getUserInfoFromServer().userId
                    const taskHistory = await definAPI.getHistoryTask(userId);
                    pointHistory.value = taskHistory.data;
                }
            });
        }

        function updatePointsListener() {
            document.addEventListener("pointsEvent", async (event) => {
                const data = event.detail.message;
                if (data.type === 'add') {
                    setTimeout(() => {
                        userTotalPoints.value = state.getUserInfoFromServer().totalPoints;
                    }, 200);
                }
            });
        }

        onMounted(() => {
            dataEventListener();
            updatePointsListener();
            waitLoadingFinish(() => {
                showDashboard();
            });
        });

        return {
            dashBoardFrame,
            modules: [Pagination, Autoplay],
            eventData,
            userBalance,
            userTotalPoints,
            pointHistory,
            showDashboard,
            hideDashboard,
            channelClick,
            groupClick,
            twitterClick,
            websiteClick
        }
    }
}
</script>

<style scoped>
.swiper {
    width: 100%;
    height: 100%;
    background-color: transparent;
}

::v-deep .swiper-pagination-bullet {
    background: #d9d9d97e;
}

::v-deep .swiper-pagination-bullet-active {
    background: #D9D9D9;
}
</style>